"use client";

import { useEffect } from "react";

import { Button } from "@/components/ui/button";

// biome-ignore lint/suspicious/noShadowRestrictedNames: <explanation>
export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <div className="z-10 flex-auto py-[125px] !pb-0 md:py-[300px]">
      <h1 className="mb-4 text-center font-zombie text-5xl lg:mb-10">
        Something went wrong!
      </h1>
      <div className="mt-10 flex items-center justify-center">
        <Button onClick={() => reset()} size="lg">
          Try again
        </Button>
      </div>
    </div>
  );
}
